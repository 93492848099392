import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import ProjectCard from '../components/ProjectCard';
import Footer from '../components/Footer';
import Loading from '../components/Loading';

const projectData = [
    {
        title: 'Goto-System',
        description: 'This is a simple plugin created for a network, which I then decided to release to everyone. What this plugin does is add the /goto command that allows you to go from one server to another without having to do /server.',
        image: 'https://builtbybit.com/attachments/model3-png.795936/?variant=display',
        link: 'https://builtbybit.com/resources/goto-system-bungee-velocity.25151/',
    },
];

const Projects: React.FC = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <div className="min-h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-200 transition-colors duration-300">
                <Navbar />

                <section id="projects" className="container mx-auto py-16 pt-24">
                    <h2 className="text-4xl font-semibold text-blue-600 dark:text-blue-400 mb-8 text-center">
                        Our Projects
                    </h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {projectData.map((project, index) => (
                            <ProjectCard
                                key={index}
                                title={project.title}
                                description={project.description}
                                image={project.image}
                                link={project.link}
                            />
                        ))}
                    </div>
                </section>

            </div>
            <Footer />
        </>
    );
};

export default Projects;
