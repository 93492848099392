import React from 'react';

const Loading: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-white dark:bg-gray-900">
      <div className="flex space-x-2">
        <div className="w-10 h-10 bg-blue-500 rounded-full animate-wave"></div>
        <div className="w-10 h-10 bg-blue-600 rounded-full animate-wave delay-200"></div>
        <div className="w-10 h-10 bg-blue-700 rounded-full animate-wave delay-400"></div>
      </div>
    </div>
  );
};

export default Loading;