import React from 'react';
import { FaDiscord, FaTelegramPlane, FaGithub } from 'react-icons/fa';

const Footer: React.FC = () => {
  return (
    <div className="bg-gray-200 dark:bg-gray-800 py-10">
      <div className="container mx-auto flex flex-col md:flex-row justify-around items-start px-4">
        <div className="w-full md:w-1/3 mb-4 text-left">
          <h2 className="text-2xl font-semibold text-blue-600 dark:text-blue-400 mb-2">
            Contact Us
          </h2>
          <p className="text-gray-700 dark:text-gray-300 mb-2">
            For questions and inquiries, feel free to reach out.
          </p>
          <p className="text-gray-700 dark:text-gray-300">
            Email: <a href="mailto:l3gendsdev.info@gmail.com" className="text-blue-600 dark:text-blue-400 hover:underline">l3gendsdev.info@gmail.com</a>
          </p>
        </div>

        <div className="w-full md:w-1/3 mb-4 text-left">
          <h2 className="text-2xl font-semibold text-blue-600 dark:text-blue-400 mb-2">
            Useful Links
          </h2>
          <div className="flex flex-col space-y-1">
            <a href="/privacypolicy" className="text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400">Privacy Policy</a>
            <a href="/tos" className="text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400">Terms of Service</a>
            <a href="/projects" className="text-gray-700 dark:text-gray-300 hover:text-blue-600 dark:hover:text-blue-400">Our Projects</a>
          </div>
        </div>
        
        <div className="w-full md:w-1/3 mb-4 text-left">
          <h2 className="text-2xl font-semibold text-blue-600 dark:text-blue-400 mb-2">
            Follow Us
          </h2>
          <div className="flex space-x-4">
            <a href="https://discord.gg/vmwaF8XY8D" target="_blank" rel="noopener noreferrer" className="text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 flex items-center">
              <FaDiscord className="h-6 w-6" />
            </a>
            <a href="https://t.me/L3gendsDev" target="_blank" rel="noopener noreferrer" className="text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 flex items-center">
              <FaTelegramPlane className="h-6 w-6" />
            </a>
            <a href="https://github.com/L3gendsDev" target="_blank" rel="noopener noreferrer" className="text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300 flex items-center">
              <FaGithub className="h-6 w-6" />
            </a>
          </div>
        </div>
      </div>

      <div className="border-t border-gray-300 dark:border-gray-700 mt-6 pt-4 text-center">
        <p className="text-gray-700 dark:text-gray-300">
          &copy; {new Date().getFullYear()} L3gend's Dev. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
