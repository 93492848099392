import React from 'react';

interface ProjectCardProps {
  title: string;
  description: string;
  image: string;
  link: string;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ title, description, image, link }) => {
  return (
    <div className="bg-gray-200 dark:bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105">
      <img src={image} alt={title} className="w-full h-41 object-cover rounded-md mb-4" />
      <h3 className="text-2xl font-bold mb-4">{title}</h3>
      <p className="text-gray-700 dark:text-gray-400 mb-4">{description}</p>
      <a
        href={link}
        className="text-blue-600 dark:text-blue-400 hover:underline font-semibold"
      >
        Learn more 
      </a>
    </div>
  );
};

export default ProjectCard;
