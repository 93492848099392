import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';
import Loading from '../components/Loading';

const Contact: React.FC = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    if (loading) {
        return <Loading />;
    }

    return (
        <>
            <div className="min-h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-200 transition-colors duration-300">
                <Navbar />

                <section id="contact" className="container mx-auto py-16 pt-24">
                    <ContactForm />
                </section>
            </div>

            <Footer />
        </>
    );
};

export default Contact;
