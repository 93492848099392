import React from 'react';
import { Link } from 'react-router-dom';

type Service = {
  title: string;
  description: string;
  infoLink: string; 
};

interface ServicesProps {
  services: Service[];
}

const Services: React.FC<ServicesProps> = ({ services }) => {
  return (
    <section className="container mx-auto py-20 md:py-28 px-4">
      <h2 className="text-4xl font-semibold text-center text-blue-600 dark:text-blue-400 mb-12">
        Our Services
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {services.map((service, index) => (
          <div
            key={index}
            className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-lg transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-xl flex flex-col justify-between"
          >
            <div>
              <h3 className="text-lg font-bold text-blue-600 dark:text-blue-400 mb-4">
                {service.title}
              </h3>
              <p className="text-gray-700 dark:text-gray-300 mb-4 flex-grow">
                {service.description}
              </p>
            </div>
            <a
              href={service.infoLink}
              target="_blank" 
              rel="noopener noreferrer" // Per evitare attacchi di tipo reverse tabnabbing
              className="inline-block bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-full text-lg transition-all duration-300 shadow-lg transform hover:scale-105 mx-auto mt-4"
            >
              More Info
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
