import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar'; 
import Footer from '../components/Footer';
import Loading from '../components/Loading';

const TermsOfService: React.FC = () => {
    const [loadingScreen, setLoadingScreen] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadingScreen(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    if (loadingScreen) {
        return <Loading />;
    }

    return (
        <>
            <div className="min-h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-200 transition-colors duration-300 flex flex-col">
                <Navbar />
                <div className="flex flex-1 justify-center items-start py-32 px-4">
                    <div className="max-w-2xl w-full">
                        <h1 className="text-4xl font-bold text-blue-600 dark:text-blue-400 mb-6 text-center">Terms of Service (L3gend's Dev)</h1>
                        <div className="space-y-6">
                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Important Note</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                L3gend's Dev is not a company but a development team that offers programming, design, and other services. We operate in an informal context, so interactions are based on mutual trust agreements.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Definitions</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                Client: refers to any individual or entity that requests and uses the services provided by L3gend's Dev.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Delivery Times</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                L3gend's Dev commits to completing the project within 4 weeks from the confirmation of the request, unless unforeseen circumstances or exceptional situations occur. Please note that any change requests during development may extend delivery times.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Delays in Delivery</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                In case of non-compliance with the agreed timelines, L3gend's Dev will offer the client a 30% discount on the next order as compensation, excluding cases of force majeure.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Limitations of Liability</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                Once the project is delivered, L3gend's Dev assumes no responsibility for misuse or modifications made by the client. It is recommended to manage the files carefully. For post-delivery assistance, a new agreement will be necessary.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Refund Policy</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                No refunds are provided for projects started or completed. However, we guarantee maximum transparency at all stages of development and transactions.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Payments and Invoices</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                Payments must be made via the payment methods agreed upon at the time of the order. Upon receipt of payment, L3gend's Dev will provide a detailed invoice for the service rendered. It is important to note that work will begin only after payment confirmation.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Communication</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                If the client does not respond for over 48 hours during critical phases or at the time of delivery, we reserve the right to suspend work without the possibility of a refund. Timely communication is essential to ensure adherence to timelines.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">No Middleman Management</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                We do not use intermediaries (MiddleMan). Our direct relationship with the client is based on trust and transparency. In case of disagreement, we reserve the right to refuse any proposal that does not comply with our terms.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Defamation and Reputation Protection</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                Any defamatory statements against L3gend's Dev will be taken very seriously. We reserve the right to take legal action or other measures to protect our reputation.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Client Rights</h2>
                            <ul className="list-disc list-inside space-y-2 text-gray-700 dark:text-gray-300">
                                <li>Receive timely and competent assistance for the services provided.</li>
                                <li>Request clarifications and information regarding the services and their functionalities.</li>
                                <li>Be informed of any changes to the Terms of Service.</li>
                                <li>Delete their account and associated data, in accordance with data protection regulations.</li>
                            </ul>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Staff Rights</h2>
                            <ul className="list-disc list-inside space-y-2 text-gray-700 dark:text-gray-300">
                                <li>Make quick decisions in case of violations of the Terms of Service.</li>
                                <li>Modify or suspend access to the services in case of improper behavior.</li>
                                <li>Receive feedback and reports from clients regarding the services offered.</li>
                                <li>Proactively manage the community to ensure a positive and respectful environment.</li>
                            </ul>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Respecting Administrators' Decisions</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                The Client agrees to respect the decisions of the administrators and moderators of the server. If the Client has questions or concerns, they are encouraged to ask respectfully and constructively.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Usage Rights</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                Once the project is delivered, the client has full ownership of the files and can use them freely for commercial purposes, sale, or public disclosure.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">File Delivery</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                The final files will be uploaded to <a href="https://wetransfer.com/" className="text-blue-600 dark:text-blue-400 underline">WeTransfer</a> and will be available for download for 5 days. After this period, it will not be possible to extend availability. Additional copies of the project can be requested at an extra cost.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Post-Delivery Modifications</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                Any changes requested after delivery will be considered new commissions and will be subject to a new evaluation of time and costs.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Customer Support</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                We are always available for any questions or concerns. Do not hesitate to contact us at any time during or after the development process for support.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Review of Conditions</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                The above conditions are subject to review and updates; every client is invited to read them carefully before proceeding.
                            </p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default TermsOfService;
