import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './pages/App';
import Projects from './pages/Projects';
import './index.css';
import { AppProvider } from './AppContext';
import Contact from './pages/Contact';
import StaffLogin from './pages/StaffLogin';
import TermsOfService from './pages/TermsOfService';
import PrivacyPolicy from './pages/PrivacyPolicy';
import StaffPage from './pages/staff/StaffMainPage';
import Punishments from './pages/staff/Punishments';
import Partners from './pages/staff/Partner';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <AppProvider>
      <Router>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/projects" element={<Projects />} />
          <Route path='/contacts' element={<Contact />} />
          <Route path='/staff-login' element={<StaffLogin />} />
          <Route path='/tos' element={<TermsOfService />} />
          <Route path='/privacypolicy' element={<PrivacyPolicy />} />

        </Routes>
      </Router>
    </AppProvider>
  </React.StrictMode>
);
