import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useAppContext } from '../AppContext';
import Services from '../components/Services';
import ContactForm from '../components/ContactForm';
import Loading from '../components/Loading';

const reviews = [
  { name: 'Pippo', review: 'Great!', rating: 5 },
  { name: 'Pluto', review: 'Highly!', rating: 4 },
  { name: 'Paperino', review: 'Amazing', rating: 5 },
  { name: 'Minnie', review: 'Fantastic.', rating: 5 },
  { name: 'Peppa Pig', review: 'Exceeded!', rating: 4 },
  { name: 'George Pig', review: 'Very.', rating: 3 },
];

const services = [
  {
    title: 'Custom Discord Bots',
    description: 'We develop custom Discord bots designed to optimize interaction and engagement within your community, with tailored functionalities that meet your specific needs.',
    infoLink: 'https://discord.gg/vmwaF8XY8D',
  },
  {
    title: 'Custom Minecraft Plugins',
    description: 'We create custom plugins for Minecraft that enhance the gaming experience, providing innovative features and improving server dynamics.',
    infoLink: 'https://discord.gg/vmwaF8XY8D',
  },
  {
    title: 'Custom Websites',
    description: 'We design and develop fully customized websites, paying attention to every detail to ensure an intuitive user interface and a smooth and engaging browsing experience.',
    infoLink: 'https://discord.gg/vmwaF8XY8D',
  },
  {
    title: 'And More...',
    description: 'Explore our additional services, from content management systems to advanced software solutions, to meet all your digital needs.',
    infoLink: 'https://discord.gg/vmwaF8XY8D',
  },
];

const App: React.FC = () => {
  const { isDarkMode } = useAppContext();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <div className={`min-h-screen bg-white dark:bg-gray-900 transition-colors duration-300`}>
        <Navbar />

        {/* Header */}
        <header className="container mx-auto text-center py-32 md:py-40">
          <div className="flex flex-col items-center space-y-8">
            {/* Logo */}
            <img
              src={isDarkMode ? "/assets/images/White.svg" : "/assets/images/Black.svg"}
              alt="Logo"
              className="w-48 h-48 md:w-64 md:h-64 transition-transform duration-300 transform hover:scale-110"
            />

            {/* Descrizione */}
            <div className="text-left">
              <h1 className="text-5xl md:text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-600 to-purple-500 dark:from-blue-400 dark:to-purple-500 animate-fade-in">
                Welcome to L3gend's Dev
              </h1>
              <p className="text-lg md:text-xl text-gray-800 dark:text-gray-300 mt-6 mb-4">
                We at L3gend's Dev develop high-quality digital solutions that are open source, free, paid, and tailored to user requests.
              </p>
              <p className="text-lg md:text-xl text-gray-800 dark:text-gray-300 mb-10">
                Explore our innovative projects and discover how we can enhance your digital presence.
              </p>
              <Link
                to="/projects"
                className="inline-block bg-blue-600 hover:bg-blue-700 text-white py-3 px-6 rounded-full text-lg transition-all duration-300 shadow-lg transform hover:scale-105"
              >
                Discover Projects
              </Link>
            </div>
          </div>
        </header>

        <Services services={services} />

        {/* Client Reviews */}
        {/*<section className="container mx-auto py-20 md:py-28 px-4">
          <h2 className="text-4xl font-semibold text-center text-blue-600 dark:text-blue-400 mb-12">
            What Clients Say
          </h2>

          <div className="relative overflow-hidden">
            <div className="flex space-x-4 animate-scroll">
              {reviews.map((review, index) => (
                <div
                  key={index}
                  className="flex-shrink-0 w-full md:w-1/3 bg-gray-100 dark:bg-gray-800 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
                >
                  <p className="text-lg text-gray-700 dark:text-gray-300 mb-4">
                    "{review.review}"
                  </p>
                  <div className="flex items-center mb-2">
                    {Array.from({ length: review.rating }, (_, i) => (
                      <svg
                        key={i}
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5 text-yellow-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 17.27l4.15 2.18a1 1 0 001.45-1.05l-.79-4.59 3.34-3.25a1 1 0 00-.56-1.71l-4.6-.68L12 2.5 10.45 8.73l-4.6.68a1 1 0 00-.56 1.71l3.34 3.25-.79 4.59a1 1 0 001.45 1.05L12 17.27z"
                        />
                      </svg>
                    ))}
                  </div>
                  <h3 className="text-lg font-bold text-blue-600 dark:text-blue-400">
                    - {review.name}
                  </h3>
                </div>
              ))}
            </div>
          </div>
        </section>*/}

        <ContactForm></ContactForm>

        {/* Footer */}
        <Footer />
      </div>
    </>
  );
};

export default App;
