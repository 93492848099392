import React, { useEffect, useState } from 'react';
import Navbar from '../components/Navbar'; 
import Footer from '../components/Footer';
import Loading from '../components/Loading';

const PrivacyPolicy: React.FC = () => {
    const [loadingScreen, setLoadingScreen] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoadingScreen(false);
        }, 2000);

        return () => clearTimeout(timer);
    }, []);

    if (loadingScreen) {
        return <Loading />;
    }

    return (
        <>
            <div className="min-h-screen bg-white dark:bg-gray-900 text-gray-900 dark:text-gray-200 transition-colors duration-300 flex flex-col">
                <Navbar />
                <div className="flex flex-1 justify-center items-start py-32 px-4">
                    <div className="max-w-2xl w-full">
                        <h1 className="text-4xl font-bold text-blue-600 dark:text-blue-400 mb-6 text-center">Privacy Policy (L3gend's Dev)</h1>
                        <div className="space-y-6">
                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Introduction</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                L3gend's Dev is committed to protecting your privacy. This Privacy Policy describes how we collect, use, and protect the personal information of our clients and users.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Information Collected</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                We collect various types of information, including:
                            </p>
                            <ul className="list-disc list-inside space-y-2 text-gray-700 dark:text-gray-300">
                                <li>Contact Information: Name, email address, and phone number.</li>
                                <li>Service-Related Information: Details about requested projects, preferences, and feedback.</li>
                                <li>Payment Information: Data necessary for processing payments, managed through secure third-party services.</li>
                            </ul>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Use of Information</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                The information collected may be used to:
                            </p>
                            <ul className="list-disc list-inside space-y-2 text-gray-700 dark:text-gray-300">
                                <li>Provide and manage the requested services.</li>
                                <li>Communicate with the client regarding the project status and support requests.</li>
                                <li>Improve our services and website.</li>
                                <li>Send promotional communications, if the client has consented.</li>
                            </ul>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Information Sharing</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                We do not sell or rent personal information to third parties. We may share information with:
                            </p>
                            <ul className="list-disc list-inside space-y-2 text-gray-700 dark:text-gray-300">
                                <li>Service Providers: Partners who assist us in managing payments and providing services.</li>
                                <li>Legal Obligations: If required by law or in response to legal proceedings.</li>
                            </ul>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Information Security</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                We take appropriate security measures to protect personal information from unauthorized access, use, or disclosure. However, no method of transmission over the Internet or electronic storage is 100% secure.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Client Rights</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                The client has the right to:
                            </p>
                            <ul className="list-disc list-inside space-y-2 text-gray-700 dark:text-gray-300">
                                <li>Access their personal information.</li>
                                <li>Request correction of inaccurate or incomplete information.</li>
                                <li>Request deletion of personal information, within legal limits.</li>
                            </ul>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Changes to the Privacy Policy</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                We reserve the right to update this Privacy Policy. In case of substantial changes, we will inform clients through our website or via email. We encourage you to check this page regularly for updates.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Contacts</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                For questions or requests regarding this Privacy Policy, you can contact us at the email address: <span className="text-blue-600 dark:text-blue-400 underline"><a href='mailto:support@l3gend.xyz'>support@l3gend.xyz</a></span>.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-gray-300">Consent</h2>
                            <p className="text-gray-700 dark:text-gray-300">
                                By using our services, you consent to the collection and use of your personal information as described in this Privacy Policy.
                            </p>

                            <p className="text-gray-700 dark:text-gray-300">
                                This Privacy Policy is a starting point and may need adjustments based on the specific legal regulations in your area. Be sure to consult a legal expert for a detailed review.
                            </p>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};

export default PrivacyPolicy;
