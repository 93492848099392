import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAppContext } from '../AppContext';


const Navbar: React.FC = () => {
  const {isDarkMode, setIsDarkMode} = useAppContext(); 

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleDarkMode = () => {
    const newMode = !isDarkMode;
    setIsDarkMode(newMode);
    if (newMode) {
      document.documentElement.classList.add('dark');
      localStorage.setItem('theme', 'dark');
    } else {
      document.documentElement.classList.remove('dark');
      localStorage.setItem('theme', 'light');
    }
  };

  return (
    <nav className="fixed top-0 left-0 w-full bg-white/20 dark:bg-gray-800/20 p-5 shadow-lg transition-colors duration-300 z-30 backdrop-blur-sm">
      <div className="container mx-auto flex justify-between items-center">
        <a href="/" className="text-2xl text-blue-600 dark:text-blue-400 font-bold">
          L3gend's Dev
        </a>

        <div className="hidden md:flex space-x-8 items-center">
          <Link to="/" className="text-gray-800 dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 transition-colors duration-300">Home</Link>
          <Link to="/projects" className="text-gray-800 dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 transition-colors duration-300">Projects</Link>
          <Link to="/contacts" className="text-gray-800 dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 transition-colors duration-300">Contacts</Link>

          <button
            onClick={toggleDarkMode}
            className="text-gray-800 dark:text-gray-200 focus:outline-none"
          >
            {isDarkMode ? (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 3v1m0 16v1m8.485-8.485l.707-.707M3 12h1m16 0h1m-8.485 8.485l.707.707M16.243 7.757l.707-.707M7.757 7.757l-.707-.707M7.757 16.243l-.707.707" />
              </svg>
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 3c.936 0 1.845.182 2.683.52a7.5 7.5 0 01-6.365 13.963A8.961 8.961 0 0012 3z" />
              </svg>
            )}
          </button>

          <a href="/staff-login" className="bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md transition-all duration-300 ml-4">
            Login
          </a>
        </div>

        <div className="md:hidden">
          <button
            onClick={toggleMenu}
            className="text-gray-800 dark:text-gray-200 focus:outline-none"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="md:hidden mt-4 space-y-4">
          <a href="/" className="block text-gray-800 dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 transition-colors duration-300">Home</a>
          <a href="/projects" className="block text-gray-800 dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 transition-colors duration-300">Projects</a>
          <a href="/contacts" className="block text-gray-800 dark:text-gray-200 hover:text-blue-600 dark:hover:text-blue-400 transition-colors duration-300">Contacts</a>

          <div className="flex justify-between items-center">
            <button
              onClick={toggleDarkMode}
              className="text-gray-800 dark:text-gray-200 focus:outline-none"
            >
              {isDarkMode ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 3v1m0 16v1m8.485-8.485l.707-.707M3 12h1m16 0h1m-8.485 8.485l.707.707M16.243 7.757l.707-.707M7.757 7.757l-.707-.707M7.757 16.243l-.707.707" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 3c.936 0 1.845.182 2.683.52a7.5 7.5 0 01-6.365 13.963A8.961 8.961 0 0012 3z" />
                </svg>
              )}
            </button>

            <a href="#" className="block bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md transition-all duration-300">
              Login
            </a>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
